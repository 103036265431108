<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-menu
      v-if="isMobile"
      offset-x
      right
    >
      <template #activator="{ on, attrs }">
        <v-icon
          dark
          large
          color="#37474F"
          v-bind="attrs"
          v-on="on"
        >
          mdi-menu
        </v-icon>
      </template>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getEmergencyMessages()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-message-alert-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Emergency Alerts
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getSuspiciousCheckIns()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-account-question-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Suspicious Check-Ins
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getManualCheckoutUsers()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-account-arrow-right-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Manual Check-Outs
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-navigation-drawer
      v-if="!isMobile"
      permanent
      color="#ECEFF1"
      expand-on-hover
      absolute
      bottom
      floating
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="nav-title-style">
              <v-list-item-icon>
                <v-icon
                  style="font-size:25px;color:#37474F;font-weight:bold;"
                >
                  mdi-apps
                </v-icon>
              </v-list-item-icon>
              Widgets
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <br>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getEmergencyMessages()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-message-alert-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Emergency Alerts
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getSuspiciousCheckIns()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-account-question-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Suspicious Check-Ins
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getManualCheckoutUsers()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-account-arrow-right-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Manual Check-Outs
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <div>
      <emergency-messages v-if="showEmergencyMessages" />
      <suspicious-checkins v-if="showSuspisiousChecIns" />
      <manual-checkouts v-if="showManualCheckOuts" />
    </div>
  </v-container>
</template>

<script>
import EmergencyMessages from './emergencymessages/EmergencyMessages';
import SuspiciousCheckIns from './suspiciouscheckIn/SuspiciousCheckIns';
import ManualCheckOuts from './manualcheckout/ManualCheckOuts.vue';

 export default {
    name: 'Widgets',
    components: {
      'emergency-messages': EmergencyMessages,
      'suspicious-checkins': SuspiciousCheckIns,
      'manual-checkouts': ManualCheckOuts,
    },
    data: () => ({
      selectedItem: -1,
      showEmergencyMessages: true,
      showSuspisiousChecIns: false,
      showManualCheckOuts: false,
    }),

    computed: {
      isMobile () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return false;
        } else {
          return true;
        }
      },
    },
    mounted () {
      this.selectedItem = 0;
    },
    methods: {
      getEmergencyMessages () {
        this.showSuspisiousChecIns = false;
        this.showManualCheckOuts = false;
        this.showEmergencyMessages = true;
      },
      getSuspiciousCheckIns () {
        this.showEmergencyMessages = false;
        this.showManualCheckOuts = false;
        this.showSuspisiousChecIns = true;
      },
      getManualCheckoutUsers () {
        this.showEmergencyMessages = false;
        this.showSuspisiousChecIns = false;
        this.showManualCheckOuts = true;
      },
    },
  };

</script>
<style scoped>
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}
.alert-style {
  color: #C62828;
  font-size: 25px;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
}
</style>
